export default {
  main_manager: [
    {
      action: ["list"],
      subject: ["Archive"]
    }
  ],
  admin: [
    {
      action: ["list"],
      subject: ["Archive"]
    }
  ],
  manager: []
};
